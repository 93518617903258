import Choices from 'choices.js';
import mapboxgl from 'mapbox-gl';

const choices = new Choices('#location-filter', {
    searchEnabled: false,
    itemSelectText: '',
});

mapboxgl.accessToken = 'pk.eyJ1IjoibnlybmdyczI0IiwiYSI6ImNtMnV0Y3EzazA0bncyaXB6NHUzdHFoYWIifQ.SjXsvugZq6DM5ZAPauS0_Q';
const map = new mapboxgl.Map({
    container: 'map',
    zoom: 1.5,
    center: [5, 30],
    style: 'mapbox://styles/nyrngrs24/cm2uxzz56004i01qk0m3sh3cm',
    antialias: true,
    renderWorldCopies: false,
    dragRotate: false,
    minZoom: 1.5,
});

// hide mapbox attribution
map.addControl(new mapboxgl.AttributionControl({
    compact: true,
    customAttribution: ''
}));

map.addControl(new mapboxgl.NavigationControl());

const geolocate = new mapboxgl.GeolocateControl({
    positionOptions: {
        enableHighAccuracy: true
    },
    trackUserLocation: true,
    fitBoundsOptions: {
        maxZoom: 8
    }
});
// Add the control to the map.
map.addControl(geolocate);

function flyToCountry(countryCode) {
    if (countryCode === 'world') {
        map.flyTo({
            center: [5, 30],
            zoom: 1.5
        });
        return;
    }
    fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${countryCode}.json?country=${countryCode}&types=country&access_token=${mapboxgl.accessToken}`)
        .then(response => response.json())
        .then(data => {
            if (data.features.length) {
                // const [longitude, latitude] = data.features[0].center;
                // map.flyTo({
                //     center: [longitude, latitude],
                //     zoom: 4,
                // });
                const bounds = data.features[0].bbox;
                map.fitBounds([
                    [bounds[0], bounds[1]], // southwestern corner
                    [bounds[2], bounds[3]]  // northeastern corner
                ], {
                    padding: 50 // adds padding around the bounds
                });
            } else {
                console.error('Country not found');
            }
        })
        .catch(error => console.error('Error with geocoding request:', error));
}

function formatAddress(address) {
    if (!address) return '';

    // Common street abbreviations that need periods
    const streetAbbreviations = {
        ' St': ' St.',
        ' Rd': ' Rd.',
        ' Dr': ' Dr.',
        ' Ave': ' Ave.',
        ' Blvd': ' Blvd.',
        ' Ln': ' Ln.',
        ' Ct': ' Ct.',
        ' Pl': ' Pl.'
    };

    // Add periods to street abbreviations
    let formattedAddress = address;
    Object.entries(streetAbbreviations).forEach(([abbr, replacement]) => {
        formattedAddress = formattedAddress.replace(new RegExp(abbr + '(?=[,]|$)', 'g'), replacement);
    });

    // Split into parts
    const parts = formattedAddress.split(',');

    // Street address is first line
    const streetAddress = parts[0].trim();

    // Combine remaining parts on second line
    const locationLine = parts.slice(1).map(part => part.trim()).join(', ');

    return [streetAddress, locationLine]
        .filter(part => part.length > 0)
        .join('<br>');
}

function showIndicator(map, features) {
    // Set filter to show shadow for both hovered and active points
    map.setFilter('juggling-clubs-layer-shadow',
        ['any',
            ['==', ['get', 'name'], features.hoveredFeature],
            ['==', ['get', 'name'], features.activeFeature]
        ]
    );

    // Set dynamic circle radius based on state
    map.setPaintProperty('juggling-clubs-layer-shadow', 'circle-radius',
        ['case',
            ['==', ['get', 'name'], features.activeFeature], 15, // Active point gets larger radius
            ['==', ['get', 'name'], features.hoveredFeature], 12, // Hovered point gets medium radius
            10 // Default radius
        ]
    );

    // map.setPaintProperty('juggling-clubs-layer-shadow', 'circle-opacity',
    //     ['case',
    //         ['==', ['get', 'name'], features.activeFeature], 0.35,
    //         ['==', ['get', 'name'], features.hoveredFeature], 0.25,
    //         0
    //     ]
    // );

    // Set dynamic blur based on state
    map.setPaintProperty('juggling-clubs-layer-shadow', 'circle-blur',
        ['case',
            ['==', ['get', 'name'], features.activeFeature], 0.6,
            ['==', ['get', 'name'], features.hoveredFeature], 0.5,
            0.5
        ]
    );
}

let activeFeatureIndex = null;
let hoveredFeatureIndex = null;

map.on('load', () => {
    geolocate.trigger();
    map.setLayoutProperty('water-point-label', 'visibility', 'none');
    map.setLayoutProperty('water-line-label', 'visibility', 'none');
    map.setLayoutProperty('continent-label', 'visibility', 'none');
    map.setLayoutProperty('country-label', 'visibility', 'none');

    map.on('zoom', () => {
        if (map.getZoom() > 3) {
            map.setLayoutProperty('country-label', 'visibility', 'visible');
        } else {
            map.setLayoutProperty('country-label', 'visibility', 'none');
        }
    });

    map.addSource('juggling-clubs', {
        type: 'geojson',
        data: 'data/jugglingclubs.geojson'
    });

    map.addLayer({
        'id': 'juggling-clubs-layer-shadow',
        'type': 'circle',
        'source': 'juggling-clubs',
        'paint': {
            'circle-radius': 12,
            'circle-color': 'rgba(0, 0, 0, 0.25)',
            'circle-blur': 0.5,
            'circle-translate': [0, 0],
            'circle-radius-transition': {
                duration: 300,
                delay: 0
            },
            'circle-blur-transition': {
                duration: 300,
                delay: 0
            },
            // 'circle-opacity': 0.25,
            // 'circle-opacity-transition': {
            //     duration: 300,
            //     delay: 0
            // }
        }
    });

    map.addLayer({
        'id': 'juggling-clubs-layer',
        'type': 'circle',
        'source': 'juggling-clubs',
        'paint': {
            'circle-radius': ['interpolate', ['linear'], ['zoom'],
                // zoom level : circle radius
                1.5, 8,
                4, 12,  // increases circle size as you zoom in
                8, 16
            ],
            'circle-color': ['get', 'color'],
            'circle-stroke-width': 1,
            'circle-stroke-color': '#ffffff',
            'circle-opacity': 0.9,
        }
    });

    map.on('mouseenter', 'juggling-clubs-layer', (e) => {
        map.getCanvas().style.cursor = 'pointer';

        hoveredFeatureIndex = e.features[0].properties.name;

        showIndicator(map, {
            hoveredFeature: hoveredFeatureIndex,
            activeFeature: activeFeatureIndex
        });
    });

    map.on('click', 'juggling-clubs-layer', (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const details = e.features[0].properties;

        const address = formatAddress(details.address);

        const popup = `
            <header class="popup__header">
                <h3 class="popup__title">${details.name}</h3>
            </header>
            <div class="popup__body">
                <div class="popup__address">${address}</div>
                <div class="links">
                    <a class="button button--small" href="${details.website}" target="_blank">View Website</a>
                </div>
                <div class="popup__last_updated"><strong>Last reported active</strong>: <br/>${details.last_updated}</div>
            </div>`;

        if (['mercator', 'equirectangular'].includes(map.getProjection().name)) {
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }
        }

        activeFeatureIndex = e.features[0].properties.name;
        showIndicator(map, {
            hoveredFeature: hoveredFeatureIndex,
            activeFeature: activeFeatureIndex
        });

        new mapboxgl.Popup({
            maxWidth: 400,
        })
            .setLngLat(coordinates)
            .setHTML(popup)
            .on('close', () => {
                activeFeatureIndex = null;
                showIndicator(map, {
                    hoveredFeature: hoveredFeatureIndex,
                    activeFeature: null
                });
            })
            .addTo(map);
    });

    // Change it back to a pointer when it leaves.
    map.on('mouseleave', 'juggling-clubs-layer', (e) => {
        map.getCanvas().style.cursor = '';

        map.getCanvas().style.cursor = '';
        hoveredFeatureIndex = null;

        showIndicator(map, {
            hoveredFeature: null,
            activeFeature: activeFeatureIndex
        });
    });

    const $countrySelect = document.getElementById('location-filter');
    const selectedCountryCode = $countrySelect.value;

    if ( selectedCountryCode ) {
        flyToCountry(selectedCountryCode);
    }

    document.getElementById('location-filter').addEventListener('change', (e) => {
        flyToCountry(e.target.value);
    });
});